import MainLayout from 'src/layouts/MainLayout';
import { lazy } from 'react';
import { RouteI } from 'src/models/router';
import { RoutePath } from 'src/enums/routePath';

const LoginPage = import('../pages/Login');
const Customer = import('../pages/Customer');
const Dashboard = import('../pages/Dashboard');
const TodoPage = import('../pages/Todo');
const ForgotPasswordPage = import('../pages/ForgotPassword');
const ResetPasswordPage = import('../pages/ResetPassword');
const InsuranceApplicationConfirmPage = import('../pages/InsuranceApplicationConfirm');

export const routes: RouteI[] = [
  {
    path: RoutePath.LOGIN,
    Component: lazy(() => LoginPage)
  },
  {
    path: RoutePath.DASHBOARD,
    Component: lazy(() => Dashboard),
    Layout: <MainLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.FORGOT_PASSWORD,
    Component: lazy(() => ForgotPasswordPage)
  },
  {
    path: RoutePath.RESET_PASSWORD,
    Component: lazy(() => ResetPasswordPage)
  },
  {
    path: RoutePath.TODO,
    Component: lazy(() => TodoPage),
    Layout: <MainLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.CUSTOMER,
    Component: lazy(() => Customer),
    Layout: <MainLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.INSURANCE_APPLICATION + RoutePath.ID,
    Component: lazy(() => InsuranceApplicationConfirmPage)
  }
];
